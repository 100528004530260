const units = [
  { value: 1, text: "B" },
  { value: 1000, text: "KB" },
  { value: 1000000, text: "MB" },
  { value: 1000000000, text: "GB" },
  { value: 1000000000000, text: "TB" },
  { value: 1000000000000000, text: "PB" },
  { value: 1000000000000000000, text: "EB" },
  { value: 1000000000000000000000, text: "ZB" },
  { value: 1000000000000000000000000, text: "YB" },
];

export const byteToValueFormatter = (valueInBytes, shortDisplay) => {
  let l = 0,
    num = parseInt(valueInBytes, 10) || 0;
  while (num >= 1000 && ++l) {
    num = num / 1000;
  }

  num = num.toString(); //If it's not already a String
  if (shortDisplay && num.indexOf(".") !== -1) {
    num = num.slice(0, num.indexOf(".") + 3);
  }

  return `${num} ${units[l].text}`;
};

export const valueToByteFormatter = (value) => {
  if (value) {
    //regex to separate numbers from letters - array
    const regex = /(\d+(?:[\\.|\\,]\d+)?)|([A-z]+)/g;
    let [input, unit] = value.match(regex);

    const foundUnit = units.find((el) => el.text === unit.toUpperCase());
    return foundUnit.value * parseFloat(input.replace(",", "."));
  }
};

export const coreFormatter = (cores) => {
  if (cores === 1) {
    return "1 Core";
  } else {
    return `${cores} cores`;
  }
};
