<template>
  <b-overlay :opacity="0.95" :show="loadingAccess" rounded="sm">
    <b-card border-variant="light" class="shadow-sm card-rounded mb-4">
      <div class="d-flex align-items-center justify-content-between">
        <p class="mb-0 custom-card-title">
          {{ titleForAccess }}
        </p>
      </div>

      <b-form-group class="mb-0 mt-2">
        <b-form-tags
          id="tags-with-dropdown"
          v-model="modelArray"
          no-outer-focus
          size="lg"
          tag-pills
        >
          <template v-slot="{ tags, disabled, addTag, removeTag }">
            <ul v-if="tags.length > 0" class="list-inline d-inline-block">
              <li v-for="tag in tags" :key="tag" class="list-inline-item">
                <b-form-tag
                  :data-cy="`user-access`"
                  :disabled="qreStatus === 'TRANSITIONING'"
                  :title="toText(tag)"
                  variant="success"
                  @remove="removeUser(tag, removeTag)"
                >
                  <b-icon
                    :icon="iconSelector(JSON.parse(tag).trusteeType)"
                    class="mr-1"
                  />
                  {{ toText(tag) }}
                </b-form-tag>
              </li>
            </ul>
            <b-dropdown
              :disabled="qreStatus === 'TRANSITIONING'"
              block
              data-cy="single-qre-role-dropdown"
              menu-class="w-100"
              size="sm"
              variant="outline-secondary"
            >
              <template #button-content>
                <b-icon icon="people-fill" />
                {{ $t("singleQRE.addUser") }}
              </template>

              <b-dropdown-form @submit.stop.prevent="() => {}">
                <b-form-group
                  :description="searchDesc"
                  :disabled="disabled"
                  :label="$t('singleQRE.searchUsers')"
                  class="mb-0"
                  label-cols-md="auto"
                  label-for="tag-search-input"
                  label-size="sm"
                >
                  <b-form-input
                    id="tag-search-input"
                    v-model="search"
                    autocomplete="off"
                    size="sm"
                    type="search"
                  />
                </b-form-group>
              </b-dropdown-form>

              <b-dropdown-divider />

              <b-dropdown-group id="dropdown-group-1">
                <b-dropdown-item-button
                  v-for="option in availableOptions"
                  :key="option.id"
                  data-cy="single-qre-role-dropdown-item"
                  @click="addUser({ option, addTag })"
                >
                  <b-icon
                    :icon="iconSelector(option.trusteeType)"
                    class="mr-1"
                  />
                  {{ option.trusteeDisplay }}
                </b-dropdown-item-button>
              </b-dropdown-group>

              <b-dropdown-text v-if="availableOptions.length === 0">
                {{ $t("singleQRE.usersEmpty") }}
              </b-dropdown-text>
            </b-dropdown>
          </template>
        </b-form-tags>
      </b-form-group>
    </b-card>
  </b-overlay>
</template>

<script>
export default {
  name: "ManageUsersCard",
  props: {
    titleForAccess: String,
    loading: Boolean,
    qreStatus: String,
    model: Array,
    choices: Array,
  },
  data() {
    return {
      loadingAccess: true,
      search: "",
      modelArray: [],
      options: [],
    };
  },
  watch: {
    model() {
      this.modelArray = this.model;
    },
    choices() {
      this.options = this.choices;
    },
    loading() {
      this.loadingAccess = this.loading;
    },
  },
  mounted() {
    this.$root.$on("bv::dropdown::show", () => {
      // gives an error if you use refs (even with nextTick and setTimeout)
      // setTimeout is needed to execute after the dropdown is shown
      if (document.getElementById("tag-search-input") !== null) {
        setTimeout(
          () => document.getElementById("tag-search-input").focus(),
          0
        );
      }
    });
  },
  methods: {
    // add tag on click for user access
    async addUser({ option }) {
      this.$emit("on-option-click", option);
    },

    // remove tag for user access
    async removeUser(tag, removeTag) {
      removeTag(tag);
      this.$emit("on-remove-click", tag);
    },

    // get display text for tag
    toText(tag) {
      const parsedTag = JSON.parse(tag);
      return parsedTag.trusteeDisplay ?? parsedTag.trustee;
    },

    iconSelector(type) {
      switch (type) {
        case "ACCOUNT":
          return "person-fill";
        case "ROLE_AT_TENANT":
          return "at";
        case "SYSTEM_ROLE":
          return "cloud-fill";
        default:
          return "";
      }
    },
  },
  computed: {
    criteria() {
      // Compute the search criteria
      return this.search.trim().toLowerCase();
    },
    availableOptions() {
      const criteria = this.criteria;
      // This is here because this.options (get /trustees) don't have an id
      // but get /acl has an id so if we try to compare the two of them then it would never match,
      // so we won't have the remove option
      const uAccess = this.modelArray.map((el) => {
        const newU = JSON.parse(el);
        delete newU.id;
        return newU;
      });
      // Filter out already selected options
      const options = this.options.filter(
        (opt) => uAccess.map((el) => el.trustee).indexOf(opt.trustee) === -1
      );
      if (criteria) {
        // Show only options that match criteria
        return options.filter(
          (opt) => opt.trustee?.toLowerCase().indexOf(criteria) > -1
        );
      }
      // Show all options available
      return options;
    },

    searchDesc() {
      if (this.criteria && this.availableOptions.length === 0) {
        return this.$i18n.t("singleQRE.searchEmpty");
      }
      return "";
    },

    mappedTags() {
      return this.modelArray.map((item) => JSON.parse(item).trustee);
    },
  },
};
</script>

<style scoped></style>
