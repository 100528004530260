var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"fileUploadModal","title":_vm.$t('uploadFile.modalTitle') + " " + (_vm.qre.name),"centered":"","hide-footer":"","size":"lg"},on:{"hidden":_vm.resetModal}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.uploadFile)}}},[_c('ValidationProvider',{attrs:{"name":"File","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-form-group',{attrs:{"description":_vm.$t('uploadFile.pickFile'),"label":_vm.$t('uploadFile.title'),"label-for":"upload-file"}},[_c('b-input-group',[_c('b-form-file',{attrs:{"id":"upload-file","drop-placeholder":_vm.$t('uploadFile.drop'),"placeholder":_vm.$t('uploadFile.choose'),"state":_vm.getValidationState(errors, valid)},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),_c('b-input-group-append',[_c('b-button',{staticClass:"custom-danger-button",staticStyle:{"border-top-right-radius":"0.25rem","border-bottom-right-radius":"0.25rem"},on:{"click":_vm.removeFile}},[_c('b-icon',{attrs:{"icon":"x"}})],1)],1),_c('b-form-invalid-feedback',{attrs:{"id":"live-feedback-file"}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"customMessages":_vm.messages,"rules":{
          regex: /^\/(?:[^\/\0\s]+\/?)*[^\/\0\s]*$/,
          required: true,
        },"name":"File Location"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
        var valid = ref.valid;
return [_c('b-form-group',{attrs:{"description":_vm.$t('uploadFile.whereToUpload'),"invalid-feedback":errors[0],"label":_vm.$t('uploadFile.location'),"label-for":"file-location"}},[_c('b-form-input',{attrs:{"id":"file-location","placeholder":_vm.$t('uploadFile.destination'),"state":_vm.getValidationState(errors, valid)},model:{value:(_vm.destination),callback:function ($$v) {_vm.destination=$$v},expression:"destination"}})],1)]}}],null,true)}),_c('div',{staticClass:"d-flex flex-row justify-content-end"},[_c('b-button',{attrs:{"pill":"","type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t("uploadFile.uploadBtn"))+" ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }