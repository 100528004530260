<template>
  <b-modal
    id="singleQreModal"
    :title="snapshotName ? 'Edit Snapshot' : 'Create Snapshot'"
    centered
    hide-footer
  >
    <div
      v-if="loading"
      class="d-flex align-content-center justify-content-center py-4"
    >
      <div>
        <b-spinner class="mr-2" label="Small Spinner" small />
      </div>
      <div>
        <p class="mb-0">Creating Snapshot</p>
      </div>
    </div>
    <ValidationObserver v-else ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(addEditSnapshot)">
        <ValidationProvider
          v-slot="{ errors, valid, pristine }"
          name="Snapshot Name"
          rules="alpha_dash"
        >
          <b-form-group
            id="input-group-snapshot"
            :label="$t('snapshotModal.name')"
            label-for="input-snapshot-name"
          >
            <b-form-input
              id="input-snapshot"
              v-model="valueName"
              :state="getValidationState(errors, valid, pristine)"
              :placeholder="$t('snapshotModal.enterName')"
            />

            <b-form-invalid-feedback
              id="live-feedback-email"
              data-cy="validation-email"
            >
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>

        <b-form-group
          id="textarea-group-snapshot"
          :label="$t('snapshotModal.comment')"
          label-for="textarea-snapshot-comment"
        >
          <b-form-textarea
            id="textarea-snapshot"
            v-model="valueComment"
            max-rows="6"
            :placeholder="$t('snapshotModal.enterComment')"
            rows="3"
          />
        </b-form-group>
        <div id="modal-footer" class="text-right">
          <b-button
            class="mr-2"
            pill
            variant="danger"
            @click="hideSnapshotModal"
          >
            {{ $t("snapshotModal.cancelSnapshot") }}
          </b-button>

          <b-button pill type="submit" variant="primary">
            {{ $t("snapshotModal.snapshotOk") }}</b-button
          >
        </div>
      </b-form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { ValidationObserver } from "vee-validate";
import { extend } from "vee-validate";
import { alpha_dash } from "vee-validate/dist/rules";

extend("alpha_dash", alpha_dash);

export default {
  name: "SnapshotModalForSingleQRE",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    snapshotName: String,
    snapshotComment: String,
    snapshotId: String,
    qre: {},
  },
  data() {
    return {
      loading: false,
      valueName: "",
      valueComment: "",
    };
  },
  watch: {
    snapshotName() {
      this.valueName = this.snapshotName;
    },
    snapshotComment() {
      this.valueComment = this.snapshotComment;
    },
  },
  methods: {
    hideSnapshotModal() {
      this.valueName = this.snapshotName;
      this.valueComment = this.snapshotComment;
      this.$bvModal.hide("singleQreModal");
    },

    async addEditSnapshot() {
      try {
        this.loading = true;
        if (this.snapshotId !== "") {
          await this.$http.patch(
            `${this.$cfg.BASE_QRE_MANAGER_URL}/v0/snapshots/${this.snapshotId}`,
            {
              qreId: this.qre.qreId,
              name: this.valueName,
              comment: this.valueComment,
            }
          );
          this.$emit("getQreForSnapshotModal");

          this.$bvModal.hide("singleQreModal");

          this.$bvToast.toast("Changes were saved", {
            title: `Success`,
            toaster: "b-toaster-bottom-right",
            variant: "success",
            solid: true,
          });
        } else {
          await this.$http.post(
            `${this.$cfg.BASE_QRE_MANAGER_URL}/v0/snapshots`,
            {
              comment: this.valueComment,
              name: this.valueName,
              qreId: this.qre.qreId,
            },
            {
              errors: {
                409: () => true,
              },
            }
          );
          this.valueName = "";
          this.valueComment = "";
          this.$emit("getQreForSnapshotModal");
          this.$bvModal.hide("singleQreModal");
        }
      } catch (err) {
        this.loading = false;
        if (err.response) console.log(err.response);
      } finally {
        this.loading = false;
      }
    },

    getValidationState(errors, valid, pristine) {
      if (pristine) return null;
      return errors[0] ? false : valid ? true : null;
    },
  },
};
</script>
